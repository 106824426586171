/* eslint-disable no-use-before-define */
const {BigInteger,SecureRandom} = require('./bigInteger')
const {ECCurveFp} = require('./ellipticCurve')
const SM3 = require('./sm3').SM3
const C1C2C3 = 0
const rng = new SecureRandom()
const {curve, G, n} = generateEcparam()

/**
 * 获取公共椭圆曲线
 */
function getGlobalCurve() {
  return curve 
}
/**
 * 生成ecparam
 */
function generateEcparam() {
  // 椭圆曲线
  const p = new BigInteger('FFFFFFFEFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF00000000FFFFFFFFFFFFFFFF', 16)
  const a = new BigInteger('FFFFFFFEFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF00000000FFFFFFFFFFFFFFFC', 16)
  const b = new BigInteger('28E9FA9E9D9F5E344D5A9E4BCF6509A7F39789F515AB8F92DDBCBD414D940E93', 16)
  const curve = new ECCurveFp(p, a, b)

  // 基点
  const gxHex = '32C4AE2C1F1981195F9904466A39C9948FE30BBFF2660BE1715A4589334C74C7'
  const gyHex = 'BC3736A2F4F6779C59BDCEE36B692153D0A9877CC62A474002DF32E52139F0A0'
  const G = curve.decodePointHex('04' + gxHex + gyHex)
  const n = new BigInteger('FFFFFFFEFFFFFFFFFFFFFFFFFFFFFFFF7203DF6B21C6052B53BBF40939D54123', 16)
  return {curve, G, n}
}

/**
 * 生成密钥对：publicKey = privateKey * G
 */
function GenerateKeyPairHex(a, b, c) {
  const random = a ? new BigInteger(a, b, c) : new BigInteger(n.bitLength(), rng)
  const d = random.mod(n.subtract(BigInteger.ONE)).add(BigInteger.ONE) // 随机数
  const privateKey = leftPad(d.toString(16), 64)
  const P = G.multiply(d) // P = dG，p 为公钥，d 为私钥
  const Px = leftPad(P.getX().toBigInteger().toString(16), 64)
  const Py = leftPad(P.getY().toBigInteger().toString(16), 64)
  const publicKey =  Px + Py
  return {privateKey, publicKey}
}

/**
 * 生成压缩公钥
 */
function CompressPublicKeyHex(s) {
  if (s.length !== 128) throw new Error('Invalid public key to compress')

  const len = s.length / 2
  const xHex = s.substr(0, len)
  const y = new BigInteger(s.substr(len , len), 16)

  let prefix = '03'
  if (y.mod(new BigInteger('2')).equals(BigInteger.ZERO)) prefix = '02'

  return prefix + xHex
}

/**
 * utf8串转16进制串
 */
function utf8ToHex(input) {
  input = unescape(encodeURIComponent(input))

  const length = input.length

  // 转换到字数组
  const words = []
  for (let i = 0; i < length; i++) {
    words[i >>> 2] |= (input.charCodeAt(i) & 0xff) << (24 - (i % 4) * 8)
  }

  // 转换到16进制
  const hexChars = []
  for (let i = 0; i < length; i++) {
    const bite = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff
    hexChars.push((bite >>> 4).toString(16))
    hexChars.push((bite & 0x0f).toString(16))
  }

  return hexChars.join('')
}

/**
 * 补全16进制字符串
 */
function leftPad(input, num) {
  if (input.length >= num) return input

  return (new Array(num - input.length + 1)).join('0') + input
}


/**
 * 转成utf8串
 */
function arrayToUtf8(arr) {
  const words = []
  let j = 0
  for (let i = 0; i < arr.length * 2; i += 2) {
    words[i >>> 3] |= parseInt(arr[j], 10) << (24 - (i % 8) * 4)
    j++
  }

  try {
    const latin1Chars = []

    for (let i = 0; i < arr.length; i++) {
      const bite = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff
      latin1Chars.push(String.fromCharCode(bite))
    }

    return decodeURIComponent(escape(latin1Chars.join('')))
  } catch (e) {
    throw new Error('Malformed UTF-8 data')
  }
}

/**
 * 转成16进制串
 */
function arrayToHex(arr) {
  return Array.from(arr).map(item => {
    item = item.toString(16)
    return item.length === 1 ? '0' + item : item
  }).join('')
}
/**
 * 转成字节数组
 */
function hexToArray(hexStr) {
  const words = []
  let hexStrLength = hexStr.length

  if (hexStrLength % 2 !== 0) {
    hexStr = leftPad(hexStr, hexStrLength + 1)
  }

  hexStrLength = hexStr.length

  for (let i = 0; i < hexStrLength; i += 2) {
    words.push(parseInt(hexStr.substr(i, 2), 16))
  }
  return words
}

/**
 * 验证公钥是否为椭圆曲线上的点
 */
function verifyPublicKey(publicKey) {
  const point = curve.decodePointHex(publicKey)
  if (!point) return false

  const x = point.getX()
  const y = point.getY()

  // 验证 y^2 是否等于 x^3 + ax + b
  return y.square().equals(x.multiply(x.square()).add(x.multiply(curve.a)).add(curve.b))
}

/**
 * 验证公钥是否等价，等价返回true
 */
function comparePublicKeyHex(publicKey1, publicKey2) {
  const point1 = curve.decodePointHex(publicKey1)
  if (!point1) return false

  const point2 = curve.decodePointHex(publicKey2)
  if (!point2) return false

  return point1.equals(point2)
}


/**
 * 加密
 */
function Encrypto(msg, publicKey, cipherMode = 1) {
  msg = typeof msg === 'string' ? hexToArray(utf8ToHex(msg)) : Array.prototype.slice.call(msg)
  publicKey = getGlobalCurve().decodePointHex(publicKey) // 先将公钥转成点

  const keypair = GenerateKeyPairHex()
  const k = new BigInteger(keypair.privateKey, 16) // 随机数 k

  // c1 = k * G
  let c1 = keypair.publicKey
  if (c1.length > 128) c1 = c1.substr(c1.length - 128)

  // (x2, y2) = k * publicKey
  const p = publicKey.multiply(k)
  const x2 = hexToArray(leftPad(p.getX().toBigInteger().toRadix(16), 64))
  const y2 = hexToArray(leftPad(p.getY().toBigInteger().toRadix(16), 64))

  // c3 = hash(x2 || msg || y2)
  const c3 = arrayToHex(SM3([].concat(x2, msg, y2)))

  let ct = 1
  let offset = 0
  let t = [] // 256 位
  const z = [].concat(x2, y2)
  const nextT = () => {
    // (1) Hai = hash(z || ct)
    // (2) ct++
    t = SM3([...z, ct >> 24 & 0x00ff, ct >> 16 & 0x00ff, ct >> 8 & 0x00ff, ct & 0x00ff])
    ct++
    offset = 0
  }
  nextT() // 先生成 Ha1

  for (let i = 0, len = msg.length; i < len; i++) {
    // t = Ha1 || Ha2 || Ha3 || Ha4
    if (offset === t.length) nextT()

    // c2 = msg ^ t
    msg[i] ^= t[offset++] & 0xff
  }
  const c2 = arrayToHex(msg)

  return cipherMode === C1C2C3 ? c1 + c2 + c3 : c1 + c3 + c2
}

/**
 * 解密
 */
function Decrypto(encryptData, privateKey, cipherMode = 1, {
  output = 'string',
} = {}) {
  privateKey = new BigInteger(privateKey, 16)

  let c3 = encryptData.substr(128, 64)
  let c2 = encryptData.substr(128 + 64)

  if (cipherMode === C1C2C3) {
    c3 = encryptData.substr(encryptData.length - 64)
    c2 = encryptData.substr(128, encryptData.length - 128 - 64)
  }

  const msg = hexToArray(c2)
  const c1 = getGlobalCurve().decodePointHex('04' + encryptData.substr(0, 128))

  const p = c1.multiply(privateKey)
  const x2 = hexToArray(leftPad(p.getX().toBigInteger().toRadix(16), 64))
  const y2 = hexToArray(leftPad(p.getY().toBigInteger().toRadix(16), 64))

  let ct = 1
  let offset = 0
  let t = [] // 256 位
  const z = [].concat(x2, y2)
  const nextT = () => {
    // (1) Hai = hash(z || ct)
    // (2) ct++
    t = SM3([...z, ct >> 24 & 0x00ff, ct >> 16 & 0x00ff, ct >> 8 & 0x00ff, ct & 0x00ff])
    ct++
    offset = 0
  }
  nextT() // 先生成 Ha1

  for (let i = 0, len = msg.length; i < len; i++) {
    // t = Ha1 || Ha2 || Ha3 || Ha4
    if (offset === t.length) nextT()

    // c2 = msg ^ t
    msg[i] ^= t[offset++] & 0xff
  }

  // c3 = hash(x2 || msg || y2)
  const checkC3 = arrayToHex(SM3([].concat(x2, msg, y2)))

  if (checkC3 === c3.toLowerCase()) {
    return output === 'array' ? msg : arrayToUtf8(msg)
  } else {
    return output === 'array' ? [] : ''
  }
}

/**
 * 签名
 */
function signature(msg, privateKey, {
  pointPool, der, hash, publicKey, userId
} = {}) {
  let hashHex = typeof msg === 'string' ? utf8ToHex(msg) : arrayToHex(msg)

  if (hash) {
    // SM3杂凑
    publicKey = publicKey || getPublicKeyFromPrivateKey(privateKey)
    hashHex = getHash(hashHex, publicKey, userId)
  }

  const dA = new BigInteger(privateKey, 16)
  const e = new BigInteger(hashHex, 16)

  // k
  let k = null
  let r = null
  let s = null

  do {
    do {
      let point
      if (pointPool && pointPool.length) {
        point = pointPool.pop()
      } else {
        point = getPoint()
      }
      k = point.k

      // r = (e + x1) mod n
      r = e.add(point.x1).mod(n)
    } while (r.equals(BigInteger.ZERO) || r.add(k).equals(n))

    // s = ((1 + dA)^-1 * (k - r * dA)) mod n
    s = dA.add(BigInteger.ONE).modInverse(n).multiply(k.subtract(r.multiply(dA))).mod(n)
  } while (s.equals(BigInteger.ZERO))

  if (der) return encodeDer(r, s) // asn.1 der 编码

  return leftPad(r.toString(16), 64) + leftPad(s.toString(16), 64)
}

/**
 * 验签
 */
function verifySignature(msg, signHex, publicKey, {der, hash, userId} = {}) {
  let hashHex = typeof msg === 'string' ? utf8ToHex(msg) : arrayToHex(msg)

  if (hash) {
    // SM3杂凑
    hashHex = getHash(hashHex, publicKey, userId)
  }

  let r; let
    s
  if (der) {
    const decodeDerObj = decodeDer(signHex) // asn.1 der 解码
    r = decodeDerObj.r
    s = decodeDerObj.s
  } else {
    r = new BigInteger(signHex.substring(0, 64), 16)
    s = new BigInteger(signHex.substring(64), 16)
  }

  const PA = curve.decodePointHex(publicKey)
  const e = new BigInteger(hashHex, 16)

  // t = (r + s) mod n
  const t = r.add(s).mod(n)

  if (t.equals(BigInteger.ZERO)) return false

  // x1y1 = s * G + t * PA
  const x1y1 = G.multiply(s).add(PA.multiply(t))

  // R = (e + x1) mod n
  const R = e.add(x1y1.getX().toBigInteger()).mod(n)

  return r.equals(R)
}

/**
 * SM3杂凑算法
 */
function getHash(hashHex, publicKey, userId = '1234567812345678') {
  // z = hash(entl || userId || a || b || gx || gy || px || py)
  userId = utf8ToHex(userId)
  const a = leftPad(G.curve.a.toBigInteger().toRadix(16), 64)
  const b = leftPad(G.curve.b.toBigInteger().toRadix(16), 64)
  const gx = leftPad(G.getX().toBigInteger().toRadix(16), 64)
  const gy = leftPad(G.getY().toBigInteger().toRadix(16), 64)
  let px
  let py
  if (publicKey.length === 128) {
    px = publicKey.substr(0, 64)
    py = publicKey.substr(64, 64)
  } else {
    const point = G.curve.decodePointHex(publicKey)
    px = leftPad(point.getX().toBigInteger().toRadix(16), 64)
    py = leftPad(point.getY().toBigInteger().toRadix(16), 64)
  }
  const data = hexToArray(userId + a + b + gx + gy + px + py)

  const entl = userId.length * 4
  data.unshift(entl & 0x00ff)
  data.unshift(entl >> 8 & 0x00ff)

  const z = SM3(data)

  // e = hash(z || msg)
  return arrayToHex(SM3(z.concat(hexToArray(hashHex))))
}

/**
 * 计算公钥
 */
function getPublicKeyFromPrivateKey(privateKey) {
  const PA = G.multiply(new BigInteger(privateKey, 16))
  const x = leftPad(PA.getX().toBigInteger().toString(16), 64)
  const y = leftPad(PA.getY().toBigInteger().toString(16), 64)
  return '04' + x + y
}

/**
 * 获取椭圆曲线点
 */
function getPoint() {
  const keypair = GenerateKeyPairHex()
  const PA = curve.decodePointHex(keypair.publicKey)

  keypair.k = new BigInteger(keypair.privateKey, 16)
  keypair.x1 = PA.getX().toBigInteger()

  return keypair
}

function bigintToValue(bigint) {
  let h = bigint.toString(16)
  if (h[0] !== '-') {
    // 正数
    if (h.length % 2 === 1) h = '0' + h // 补齐到整字节
    else if (!h.match(/^[0-7]/)) h = '00' + h // 非0开头，则补一个全0字节
  } else {
    // 负数 
    h = h.substr(1)

    let len = h.length
    if (len % 2 === 1) len += 1 // 补齐到整字节
    else if (!h.match(/^[0-7]/)) len += 2 // 非0开头，则补一个全0字节

    let mask = ''
    for (let i = 0; i < len; i++) mask += 'f'
    mask = new BigInteger(mask, 16)

    // 对绝对值取反，加1
    h = mask.xor(bigint).add(BigInteger.ONE)
    h = h.toString(16).replace(/^-/, '')
  }
  return h
}

class ASN1Object {
  constructor() {
    this.tlv = null
    this.t = '00'
    this.l = '00'
    this.v = ''
  }

  /**
   * 获取 der 编码比特流16进制串
   */
  getEncodedHex() {
    if (!this.tlv) {
      this.v = this.getValue()
      this.l = this.getLength()
      this.tlv = this.t + this.l + this.v
    }
    return this.tlv
  }

  getLength() {
    const n = this.v.length / 2 // 字节数
    let nHex = n.toString(16)
    if (nHex.length % 2 === 1) nHex = '0' + nHex // 补齐到整字节

    if (n < 128) {
      // 短格式，以 0 开头
      return nHex
    } else {
      // 长格式，以 1 开头
      const head = 128 + nHex.length / 2 // 1(1位) + 真正的长度占用字节数(7位) + 真正的长度
      return head.toString(16) + nHex
    }
  }

  getValue() {
    return ''
  }
}

class DERInteger extends ASN1Object {
  constructor(bigint) {
    super()

    this.t = '02' // 整型标签说明
    if (bigint) this.v = bigintToValue(bigint)
  }

  getValue() {
    return this.v
  }
}

class DERSequence extends ASN1Object {
  constructor(asn1Array) {
    super()

    this.t = '30' // 序列标签说明
    this.asn1Array = asn1Array
  }

  getValue() {
    this.v = this.asn1Array.map(asn1Object => asn1Object.getEncodedHex()).join('')
    return this.v
  }
}

/**
 * 获取 l 占用字节数
 */
function getLenOfL(str, start) {
  if (+str[start + 2] < 8) return 1 // l 以0开头，则表示短格式，只占一个字节
  return +str.substr(start + 2, 2) & 0x7f + 1 // 长格式，取第一个字节后7位作为长度真正占用字节数，再加上本身
}

/**
 * 获取 l
 */
function getL(str, start) {
  // 获取 l
  const len = getLenOfL(str, start)
  const l = str.substr(start + 2, len * 2)

  if (!l) return -1
  const bigint = +l[0] < 8 ? new BigInteger(l, 16) : new BigInteger(l.substr(2), 16)

  return bigint.intValue()
}

/**
 * 获取 v 的位置
 */
function getStartOfV(str, start) {
  const len = getLenOfL(str, start)
  return start + (len + 1) * 2
}

  /**
   * ASN.1 der 编码，针对 sm2 签名
   */
function encodeDer(r, s) {
    const derR = new DERInteger(r)
    const derS = new DERInteger(s)
    const derSeq = new DERSequence([derR, derS])

    return derSeq.getEncodedHex()
  }

  /**
   * 解析 ASN.1 der，针对 sm2 验签
   */
function decodeDer(input) {
    // 结构：
    // input = | tSeq | lSeq | vSeq |
    // vSeq = | tR | lR | vR | tS | lS | vS |
    const start = getStartOfV(input, 0)

    const vIndexR = getStartOfV(input, start)
    const lR = getL(input, start)
    const vR = input.substr(vIndexR, lR * 2)

    const nextStart = vIndexR + vR.length
    const vIndexS = getStartOfV(input, nextStart)
    const lS = getL(input, nextStart)
    const vS = input.substr(vIndexS, lS * 2)

    const r = new BigInteger(vR, 16)
    const s = new BigInteger(vS, 16)

    return {r, s}
  }

module.exports = {
  GenerateKeyPairHex, // 生成sm2秘钥对
  CompressPublicKeyHex, //公钥压缩
  Encrypto, //加密
  Decrypto, //解密
  // 以下方法为非必须方法
  
  comparePublicKeyHex, // 验证公钥是否等价
  signature, // 签名 
  verifySignature, // 验证签名
  // getPublicKeyFromPrivateKey, // 用私钥生成公钥,属于内部方法
  // getPoint,  //获取椭圆曲线点 属于内部方法
  verifyPublicKey, //验证公钥是否为椭圆曲线上的点
}
