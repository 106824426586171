import axios from "axios";
const sm2 = require("./sm2");
const sm3 = require("./sm3");
const sm4 = require("./sm4");

let aadInMemory = "";
let keyInMemory = "";
let SMEncryptoStatus = false;

const API = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    TerminalName: process.env.VUE_APP_TERMINAL_NAME,
    BuildVersion: process.env.VUE_APP_BUILD_VERSION,
    timeout: 30000,
    // 其他配置...
});

function SM3Encrypto(target){
    return sm3.Encrypto(target)
}

function GetPrivateKey() {
    let croptyKey = localStorage.getItem("PrivateKey");
    return (
        croptyKey.slice(16, 32) +
        croptyKey.slice(48, 64) +
        croptyKey.slice(80, 96) +
        croptyKey.slice(112, 128)
    );
}

// 生成sm2秘钥对
function GenerateSM2Keys() {
    let terminalCode = localStorage.getItem("TerminalCode");
    if (terminalCode == null || terminalCode == undefined || terminalCode == "") {
        let keypair = sm2.GenerateKeyPairHex();
        keypair.publicKey = keypair.publicKey.toUpperCase();
        let newprivateKey =
            keypair.publicKey.slice(64, 80) +
            keypair.privateKey.slice(0, 16) +
            keypair.publicKey.slice(16, 32) +
            keypair.privateKey.slice(16, 32) +
            keypair.publicKey.slice(32, 48) +
            keypair.privateKey.slice(32, 48) +
            keypair.publicKey.slice(48, 64) +
            keypair.privateKey.slice(48, 64) +
            keypair.publicKey.slice(64, 80);
        localStorage.setItem("AccessToken", ""); // 初始化账户识别码
        localStorage.setItem("PrivateKey", newprivateKey.toUpperCase()); // 存储的是加密后sm2私钥 使用需要使用GetPrivateKey解密
        localStorage.setItem("TerminalCode",(
                sm3.Encrypto(keypair.publicKey + process.env.VUE_APP_TERMINAL_NAME) +
                sm2.CompressPublicKeyHex(keypair.publicKey) +
                sm4.Utf8ToHex(process.env.VUE_APP_TERMINAL_NAME)
            ).toUpperCase()); // 存储的是压缩后的sm2公钥
        aadInMemory = "";
        keyInMemory = "";
    }
    return {
        privateKey: localStorage.getItem("PrivateKey"),
        terminalCode: localStorage.getItem("TerminalCode"),
    };
}
async function delay(ms) {  
    return new Promise(resolve => setTimeout(resolve, ms));  
}  
async function getRooter() {
    let baseRouter =[{name: "账户登出",path: "/logout",component: () => import("@/views/LogOut")},{name: "账户登录",path: "/login",component: () => import("@/views/LoginPage")}];
    let pannel91 = {path: "",component: () => import("@/views/91Pannel"),children: []}
    let pannel39 = {path: "",component: () => import("@/views/39Pannel"),children: []}
    let pannel391 = {path: "",component: () => import("@/views/391Pannel"),children: []}
    let workPannel = {path: "",component: () => import("@/views/BasicEdition/root"),children: []}
    while(!SMEncryptoStatus){await delay(10)}
    let AccessTokenInLocalStorage = localStorage.getItem("AccessToken")
        console.log(AccessTokenInLocalStorage)
    if( AccessTokenInLocalStorage =="" || AccessTokenInLocalStorage == undefined ){
        return baseRouter
    }else{
        return await API.get("/console/terminal/v1/product", {
            headers: {
                AccessToken: AccessTokenInLocalStorage,
            },
            params: {
                code: "top",
                system: "Console",
                edition: process.env.VUE_APP_TERMINAL_EDITION
            },
        }).then(response => {
            if(response.status == 200){
                response.data.productRepeat.forEach((children) =>{
                    if(children.viewModel == "91Pannel"){
                        pannel91.children.push({
                            name: children.name,
                            path: children.code,
                            components: {
                                pannel_9: resolve => require([`@/${children.mainComponent}.vue`],resolve),
                                pannel_1: resolve => require([`@/${children.rightComponent}.vue`],resolve),
                            },
                        })
                    }else if(children.viewModel == "39Pannel"){
                        pannel39.children.push({
                            name: children.name,
                            path: children.code,
                            components: {
                                pannel_3: resolve => require([`@/${children.leftComponent}.vue`],resolve),
                                pannel_9: resolve => require([`@/${children.mainComponent}.vue`],resolve),
                            },
                        })
                    }else if(children.viewModel == "391Pannel"){
                        pannel391.children.push({
                            name: children.name,
                            path: children.code,
                            components: {
                                pannel_3: resolve => require([`@/${children.leftComponent}.vue`],resolve),
                                pannel_9: resolve => require([`@/${children.mainComponent}.vue`],resolve),
                                pannel_1: resolve => require([`@/${children.rightComponent}.vue`],resolve),
                            },
                        })
                    }else{
                        workPannel.children.push({
                            name: children.name,
                            path: children.code,
                            component: resolve => require([`@/${children.mainComponent}.vue`],resolve),
                        })
                    }
                })
                workPannel.children.push(pannel91)
                workPannel.children.push(pannel39)
                workPannel.children.push(pannel391)
                baseRouter.push(workPannel)
            }
            return baseRouter
        })
    }
}

async function registTerminal() {
    return await API.get("/terminal/registration", {
        headers: {
            "Encrypto-Type": "SM2",
        },
    }).then((response) => {
        if (response.status == 200) {
            console.log("TerminalRegister Success! sm4key is required~")
            setSM4Keygin(response.data.SMkey, response.data.AAD);
        } else {
            console.log("TerminalRegister fall :", response);
        }
        return getSM4Keygin();
    });
}

function setSM4Keygin(sm4key, aad) {
    const SM3Code = sm3.Encrypto(sm4key + aad);
    const newSms4 =
        SM3Code.substring(0, 32) +
        sm4key.substring(0, 6) +
        aad.substring(0, 8) +
        sm4key.substring(6, 12) +
        aad.substring(8, 15) +
        sm4key.substring(12, 15) +
        aad.substring(15, 21) +
        sm4key.substring(15, 20) +
        aad.substring(21, 27) +
        sm4key.substring(20, 27) +
        aad.substring(27, 30) +
        sm4key.substring(27, 32) +
        aad.substring(30, 32) +
        SM3Code.substring(32);
    localStorage.setItem("SM4Cipher", newSms4.toUpperCase());
}

function getSM4Keygin() {
    if (aadInMemory != "" && keyInMemory != "") {
        return { key: keyInMemory, aad: aadInMemory };
    }
    const SM4Cipher = localStorage.getItem("SM4Cipher");
    if (SM4Cipher == null) {
        throw new Error("SM4Cipher 信息为空");
    }
    const sm4key =
        SM4Cipher.substring(32, 38) +
        SM4Cipher.substring(46, 52) +
        SM4Cipher.substring(59, 62) +
        SM4Cipher.substring(68, 73) +
        SM4Cipher.substring(79, 86) +
        SM4Cipher.substring(89, 94);

    const aad =
        SM4Cipher.substring(38, 46) +
        SM4Cipher.substring(52, 59) +
        SM4Cipher.substring(62, 68) +
        SM4Cipher.substring(73, 79) +
        SM4Cipher.substring(86, 89) +
        SM4Cipher.substring(94, 96);
    if (sm3.Encrypto(sm4key + aad).toUpperCase() == SM4Cipher.substring(0, 32) + SM4Cipher.substring(96)) {
        aadInMemory = aad;
        keyInMemory = sm4key;
        SMEncryptoStatus = true; // 国密体系建立完成
        return { key: sm4key, aad: aad };
    } else {
        localStorage.clear();
        throw new Error("SM4Cipher信息解密异常!");
    }
}
// 请求拦截器
API.interceptors.request.use(
    // async (config) => {
    (config) => {
        const terminalCode = localStorage.getItem("TerminalCode");
        if (terminalCode == "" || terminalCode == null || terminalCode == undefined) {
            window.location.href = "/";
        }
        let smkeys = GenerateSM2Keys(); // 优先检查秘钥对是否失效,如果失效将导致sm3的加盐信息失效
        let EncryptoType = "none";
        let encryData = smkeys.terminalCode.substring(0, 64);
        config.url = config.url + "?r=" + Math.random() // 所有的请求都添加一个随机数,目的是禁止前端使用缓存
        if (config.method === "post" || config.method === "put") {
            let configData = JSON.stringify(config.data); // json 方式
            if(config.headers["Content-Type"] == undefined || config.headers["Content-Type"] == "application/x-www-form-urlencoded"){
                configData = config.data;
            }
            if(config.method === "post"){
                config.headers["Ticket"] = sm3.Encrypto(configData)
            }
            let iv = sm3.Encrypto(configData).toUpperCase().substring(0, 32);
            encryData = configData + encryData; // 用作sm3指纹验签
            if (process.env.VUE_APP_ABLE_REQUEST_CRYPTO == "true") {
                //判断是否对请求数据进行加密
                EncryptoType = "SM4";
                const SM4Keygin = getSM4Keygin();
                config.data =
                    sm4.Encrypto(configData, SM4Keygin.key, iv).toUpperCase() + iv;
            }
        } else if (config.method === "get" || config.method === "delete") {
            let paramsValue = "";
            if (config.params != null) {
                const params = Object.keys(config.params).sort(); // 获取参数键并按字母顺序排序
                const sortedParams = params.reduce((result, key) => {
                    result[key] = config.params[key]; // 构建新的有序参数对象
                    return result;
                }, {});
                // 使用排序后的参数
                config.params = sortedParams;
                // 遍历排序后的参数
                Object.keys(sortedParams).forEach((key) => {
                    paramsValue += "&" + key + "=" + sortedParams[key];
                });

            }
            encryData = config.baseURL + config.url + paramsValue + encryData; // 用作sm3指纹验签
        } else {
            return Promise.reject("请求方式不合法");
        }
        config.headers["AccessToken"] = localStorage.getItem("AccessToken");
        config.headers["TerminalCode"] = smkeys.terminalCode;
        config.headers["Verification"] = sm3.Encrypto(encryData).toUpperCase();
        config.headers["Encrypto-Type"] = EncryptoType;
        return config;
    },
    (error) => {
        // 对请求错误做些什么
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// 响应拦截器（如果需要的话）
API.interceptors.response.use(
    (response) => {
        if (response.status == null ){
            return {
                status : "500.1",
                data:{
                    msg:"网络连接错误,请求URL无法抵达 \n\n\n"
                }
            }
        }
        if (response.status == 200) {
            if (response.headers.get("Encrypto-Type") == "SM2") {
                // 这个是采用sm2加密的返回信息
                let newbody = sm2.Decrypto(response.data.substring(2),GetPrivateKey(),1);
                if (newbody != "") {
                    response.data = JSON.parse(newbody);
                }
            }
            if (response.headers.get("Encrypto-Type") == "SM4") {
                const SM4Keygin = getSM4Keygin();
                let iv = response.data.substring(response.data.length - 32,response.data.length);
                let decodeString = sm4.Decrypto(response.data.substring(64, response.data.length - 32),SM4Keygin.key,iv);
                if (sm3.Encrypto(decodeString + getSM4Keygin().aad) ==response.data.substring(0, 64)) {
                    response.data = JSON.parse(decodeString);
                } else {
                    console.log(sm3.Encrypto(decodeString + getSM4Keygin().aad),response.data.substring(0, 64));
                    response.status = 403;
                    response.data = "验签不通过,数据被篡改";
                }
            }
            // response.headers.set("Encrypto-Type", "none");
            
        }else{
            console.log("请求响应码错误!")
        }
        return response;
    },
    (error) => {
        console.log("error in httpservice.js",error);
         let errors = {
                status : error.response.status,
                data:{
                    msg: error.response.data.msg,
                }
            }
        if (error.code == "ERR_NETWORK") {
            return {
                status : "500.1",
                data:{
                    msg:"网络连接错误,请求URL无法抵达 \n\n\n"+error.config.url
                }
            }
        }
        // console.log(error.response.status);
        // console.log(error.response.data.msg);
        console.log( error.response)
        return  errors
    }
);

const GlobalService = {
    API,
    registTerminal,
    getSM4Keygin,
    setSM4Keygin,
    SM3Encrypto,
    getRooter,
    GetPrivateKey,
    GenerateSM2Keys,
};
export default GlobalService;