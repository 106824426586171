<template>
  <div id="app" v-loading="loadingConsoleMain">
    <router-view class="mainRouter" />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
        return {
          loadingConsoleMain:false,
        }
      },
  methods: {},
};
</script>

<style>
* {
  margin: 0px;
  font-size: 12px;
  color: #333;
}
div {
  user-select: none;
}
html {
  background: #fff;
}
button,
input {
  outline: none !important;
}

#app {
  width: 100vw;
  min-width: 1000px;
  height: 100vh;
  margin: 0;
  padding: 0;
}
.el-icon-copy-document {
  cursor: pointer;
}
.text-right {
  text-align: right;
}
.mainRouter {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.el-date-table td.prev-month span,.el-date-table td.next-month span{
  color: #c0c4cc;
}

.el-date-table td.end-date span,.el-date-table td.start-date span{
  color: #fff;
}
.el-date-table td.today span {
  color: orange;
}
.el-aside {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 40px);
  width: 200px;
  padding: 0px;
  margin: 0px;
  border-right: 1px solid #ebebeb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 2px 6px rgba(0, 0, 0, 0.04);
}
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
  background: #f4f4f4;
}
.el-form {
    margin: 0px !important;
}
.el-form-item {
  margin-bottom: 10px;
}
.el-button--success span, .el-button--primary span, .el-button--warning span, .el-button--danger span, .el-button--info span{
  color: #fff
}
</style>
